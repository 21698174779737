import React, { useState } from "react";
import "./menuouvrantdvpariege.css";
import logomenuouvrant from "../../images/logodvpariege.png";
const MenuOuvrant = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu-container">
      <div
        className={`menu-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu-nav ${isMenuOpen ? "open" : ""}`}>
        <ul className="ulMenuOuvrantDvpAriege">
          <div
            className={`menu-icon ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
            <a className="aMenuOuvrantTemplateBasic" href="/">
              <span>MenuPrincipal</span>
            </a>
          <a className="aMenuOuvrantTemplateBasic" href="/creationdvp">
            <span>Votre site internet sur mesure</span>
          </a>
            <a className="aMenuOuvrantTemplateBasic" href="/menu1template">
              <span>Notre modèles de site à 30€/mois</span>
            </a>
            <a className="aMenuOuvrantTemplateBasic" href="/nosservices">
              <span>Nos Services</span>
            </a>
            <a className="aMenuOuvrantTemplateBasic" href="/mentionslegales">
              <span className="spanEnSavoirPlus">Mentions Légales</span>
            </a>
        </ul>
        <div className="filtreMenuOuvrantDvpAriege">
          <img
            className="logoMenuOuvrantDvpAriege"
            src={logomenuouvrant}
            alt="img logo dvpariege"
          />
        </div>
      </nav>
    </div>
  );
};

export default MenuOuvrant;
